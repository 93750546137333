// export const PAY_CHANNEL = {
//   SELF_WEB: 2,
// }

// For web only
export const DELIVERY_CHANNEL = {
  // WALK_IN: 1,
  // PICK_UP: 2,
  // DELIVERY: 3,

  TAKEAWAY: 2,
  DINE_IN: 22,
  PICK_UP: 32,
  DELIVERY: 42,
};

export const DELIVERY_CHANNEL_PATH = {
  // WALK_IN: 'walk-in',
  // PICK_UP: 'pickup',
  // DELIVERY: 'delivery',

  TAKEAWAY: 'takeaway',
  DINE_IN: '',
  PICK_UP: 'pickup',
  DELIVERY: 'delivery',
};

export const DELIVERY_STEP = {
  DELIVERY_INFO: 1,
  CUSTOMER_INFO: 2,
};

export const PICK_UP_STEP = {
  PICK_UP_INFO: 1,
  CUSTOMER_INFO: 2,
};

export const SCHEDULE_TYPE = {
  NOW: 1,
  IN_ADVANCE: 2,
};

export const PAY_OPTION = {
  PAY_AT_COUNTER: 1,
  PAY_ONLINE: 2,
};
