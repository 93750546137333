<template>
  <!-- <AppBtn @click="removeItem">Remove from cart</AppBtn>
    <AppBtn @click="clearAll">Clear cart</AppBtn> -->
  <div class="page-wrap py-2">
    <v-row v-if="form.length > 0">
      <v-col v-for="(item, index) in form" :key="index" cols="12" class="d-flex py-1">
        <div
          class="d-flex flex-column flex-sm-row align-start align-sm-center flex-grow-1 cart-wrap px-3 py-2 px-sm-6 py-sm-4 mx-1"
        >
          <!-- <img v-if="!isMobileView" class="cart-img mr-6" :src="item.images[0].url" alt="cart-img"> -->
          <div class="set-item-wrap w-100">
            <div class="d-flex justify-space-between align-center mb-2">
              <div class="set-item__title">
                {{ item.label }}
              </div>
              <v-icon
                v-if="isMobileView"
                class="delete-icon"
                color="var(--primary-black-color)"
                size="23"
                @click="removeItem(index)"
                >mdi-trash-can-outline</v-icon
              >
            </div>
            <ul>
              <li v-for="(cartItem, itemIndex) in item.items" :key="itemIndex" class="cart-item mb-1">
                {{ `${cartItem.name} x ${cartItem.qty}` }}
              </li>
            </ul>
          </div>
          <div v-if="!isMobileView" class="set-item__price mr-2">
            {{ `${$t('lbl.rm')}${priceFormat(item.price)}` }}
          </div>
          <!-- <v-spacer></v-spacer> -->
          <div class="d-flex justify-space-between align-center" :class="{ 'w-100': isMobileView }">
            <div v-if="isMobileView" class="set-item__price mr-2">
              {{ `${$t('lbl.rm')}${priceFormat(item.price)}` }}
            </div>
            <div class="set-item-qty-wrap">
              <PlusMinusField v-model="item.qty" :min-value="1" filled outlined dense />
            </div>
          </div>
        </div>
        <v-icon v-if="!isMobileView" class="delete-icon" color="var(--primary-black-color)" size="40" @click="removeItem(index)"
          >mdi-trash-can-outline</v-icon
        >
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" class="py-1">
        <div class="d-flex justify-center align-center cart-wrap px-6 py-6">
          <div class="font-1dot25r font-weight-medium">
            {{ $t('orders.lbl.cartEmpty') }}
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="bottom-bar d-flex justify-space-between">
      <div class="d-flex flex-column justify-center">
        <div class="total-text">{{ $t('lbl.total') }}</div>
        <div class="total-price">
          <span class="font-14">{{ $t('lbl.rm') }}</span>
          <span>{{ priceFormat(totalPrice) }}</span>
        </div>
      </div>
      <div class="d-flex align-center">
        <template v-if="form.length > 0">
          <AppBtn class="btn-grey mr-2" :height="40" :width="90" @click="addMore">{{ $t('cart.act.addMore') }}</AppBtn>
          <AppBtn class="btn-orange" :height="40" :width="90" @click="proceed">{{ $t('act.checkout') }}</AppBtn>
        </template>
        <AppBtn v-else class="btn-orange" :height="40" :width="110" @click="navigatePush('MenuAll')">{{
          $t('orders.act.orderNow')
        }}</AppBtn>
      </div>
    </div>
    <CommonModal v-model="successModal" :modal-size="'modal-xs'" :header="false" :footer="false">
      <template #body>
        <div class="modal-body d-flex align-center">
          <v-icon class="mr-2" color="var(--primary-color)" size="60">mdi-check-circle-outline</v-icon>
          <div class="modal-body__text">{{ $t('cart.success.order.confirm') }}</div>
        </div>
      </template>
    </CommonModal>
    <CommonModal
      v-model="focItemModalShow"
      class="foc-modal"
      :modal-size="'modal-sm'"
      :title="'Your order entitle these free items:'"
      :title-style="{ fontSize: '1.1rem' }"
      :persistent="true"
      :close-btn="false"
    >
      <template #body>
        <div class="pa-3">
          <!-- <div class="mb-3 font-1dot1r font-weight-medium">Your order entitle these free items:</div> -->
          <!-- <div class="font-1dot25r font-weight-regular mb-2">
            Please choose your free drink below:
          </div> -->
          <div class="mb-2 font-weight-medium">Please choose 1:</div>
          <div class="menu-item-wrap">
            <div
              v-for="(item, index) in selectableFocItems"
              :key="index"
              class="d-flex flex-column align-center text-center item-wrap cursor-pointer pa-2"
              :class="{ selected: selectableFocItem.id == item.id }"
              @click="selectFocItem(FOC_ITEM_TYPE.SELECTABLE, item)"
            >
              <img class="item-img" :src="getItemImg(item.images[0].url)" alt="item" />
              <div class="font-1r">{{ item.name }}</div>
            </div>
          </div>
          <template v-if="fixedFocItems.length > 0">
            <v-divider class="my-3"></v-divider>
            <!-- <div class="mb-2 font-weight-medium">Choose 1 item</div> -->
            <div v-for="(item, index) in fixedFocItems" :key="index" class="menu-item-wrap">
              <div
                class="d-flex flex-column align-center text-center item-wrap cursor-pointer pa-2"
                :class="{ selected: fixedFocItem.id == item.id }"
                @click="selectFocItem(FOC_ITEM_TYPE.FIXED, item)"
              >
                <img class="item-img" :src="getItemImg(item.images[0].url)" alt="item" />
                <div class="font-1r">{{ item.name }}</div>
              </div>
            </div>
          </template>
        </div>
      </template>
      <template #footer>
        <div class="foc-footer-wrap">
          <AppBtn class="btn-black-border btn-no-border w-50" :height="50" @click="skipFocItem">{{ 'No Thanks' }}</AppBtn>
          <AppBtn class="btn-orange-border btn-no-border w-50" :height="50" :loading="focItemLoading" @click="confirmFocItem">{{
            'Continue'
          }}</AppBtn>
        </div>
      </template>
    </CommonModal>

    <CommonModal
      v-model="upsellModalShow"
      class="foc-modal"
      :modal-size="'modal-sm'"
      :title="'Limited Time Offer'"
      :title-style="{ fontSize: '1.1rem' }"
      :persistent="true"
      :close-btn="false"
    >
      <template #body>
        <div class="px-3 py-5">
          <div class="font-1dot1r font-weight-regular">
            <ul>
              <li v-for="(item, index) in filteredUpsellArr" :key="index" class="mb-3">
                {{ item.msg }}
                <!-- <v-divider v-if="(index < filteredUpsellArr.length - 1) && (item.type != filteredUpsellArr[index + 1].type)" class="my-divider mx-2 my-2"></v-divider> -->
              </li>
            </ul>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="foc-footer-wrap">
          <AppBtn class="btn-black-border btn-no-border w-50" :height="50" @click="skipUpsell">{{ 'No Thanks' }}</AppBtn>
          <AppBtn class="btn-orange-border btn-no-border w-50" :height="50" @click="upsellAddMore">{{ 'Add More' }}</AppBtn>
        </div>
      </template>
    </CommonModal>

    <DeliveryChannelModal v-model="deliveryChannelModalShow" @selected="selected" />
    <MessageModal
      v-if="errMsgModalShow"
      v-model="errMsgModalShow"
      :modal-size="'modal-xs-2'"
      :header-title="msgModalTitle"
      :msg="msgModalBody"
      @confirm="errMsgModalShow = false"
    >
      <template #header>
        <div></div>
      </template>
    </MessageModal>
    <v-overlay :value="overlay" :z-index="500">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { DELIVERY_CHANNEL, DELIVERY_CHANNEL_PATH, DELIVERY_STEP, PICK_UP_STEP } from '@/common/enum/checkout';
import { menuItemApi } from '@/api/menu';
import DeliveryChannelModal from '@/views/Order/delivery-channel';
import PlusMinusField from '@/components/PlusMinusField';
import CommonModal from '@/components/Modal';
import MessageModal from '@/components/Modal/MessageModal';

export default {
  name: 'Cart',
  components: {
    CommonModal,
    DeliveryChannelModal,
    PlusMinusField,
    MessageModal,
  },
  data() {
    return {
      successModal: false,
      deliveryChannelModalShow: false,
      form: [],
      loading: false,
      eventCB: null,
      focItemList: [],
      focItemModalShow: false,
      focItemLoading: false,

      overlay: false,
      defaultImg: require('@/assets/logo.png'),

      selectableFocItem: {},
      fixedFocItem: {},

      FOC_ITEM_TYPE: {
        SELECTABLE: 0,
        FIXED: 1,
      },

      UPSELL_TYPE: {
        ALL: 0,
        PICK_UP: 3,
        DELIVERY: 4,
      },

      errMsgModalShow: false,
      msgModalTitle: '',
      msgModalBody: 'You have not selected any items.',

      upsellModalShow: false,
      upsellLoading: false,
      filteredUpsellArr: [],
    };
  },
  computed: {
    ...mapState({
      menuList: state => state.menu.menuList,
      menuItemList: state => state.menu.menuItemList,
      menuItemOptList: state => state.menu.menuItemOptList,
      menuItemOptGrpList: state => state.menu.menuItemOptGrpList,
      menuItemOptGrpSubList: state => state.menu.menuItemOptGrpSubList,
    }),
    ...mapGetters(['getCartItems', 'getFocCartItems', 'getPlacedFocCartItems', 'getPreSelectedChannel']),
    totalPrice() {
      let total = 0;
      for (let item of this.form) {
        total = total + item.qty * item.price;
      }
      return total;
    },
    selectableFocItems() {
      // two items select one
      return this.focItemList.filter(item => item.flag == this.FOC_ITEM_TYPE.SELECTABLE);
    },
    fixedFocItems() {
      return this.focItemList.filter(item => item.flag == this.FOC_ITEM_TYPE.FIXED);
    },
  },
  watch: {
    form: {
      deep: true,
      handler(value) {
        this.updateWholeCart(value);
      },
    },
  },
  created() {
    this.SET_DRAWER_TITLE('Cart');
    this.form = JSON.parse(JSON.stringify(this.getCartItems));
    if (this.isUndefinedNullOrEmpty(this.menuList)) this.fetchMenuList();
    if (this.isUndefinedNullOrEmpty(this.menuItemList)) this.fetchMenuItemList();
    if (this.isUndefinedNullOrEmpty(this.menuItemOptList)) this.fetchMenuItemOptList();
    if (this.isUndefinedNullOrEmpty(this.menuItemOptGrpList)) this.fetchMenuItemOptGrpList();
    if (this.isUndefinedNullOrEmpty(this.menuItemOptGrpSubList)) this.fetchMenuItemOptGrpSubList();

    if (!this.isUndefinedNullOrEmpty(this.getFocCartItems)) this.clearFocCart();
    if (!this.isUndefinedNullOrEmpty(this.getPlacedFocCartItems)) this.clearPlacedFocCart();
  },
  methods: {
    ...mapActions([
      'fetchMenuList',
      'fetchMenuItemList',
      'fetchMenuItemOptList',
      'fetchMenuItemOptGrpList',
      'fetchMenuItemOptGrpSubList',
      'addToCart',
      'removeFromCart',
      'clearCart',
      'clearFocCart',
      'clearPlacedFocCart',
      'updateWholeCart',
      'addToFocCart',
    ]),
    ...mapMutations(['SET_DRAWER_TITLE', 'SET_DELIVERY_FEE']),
    removeItem(index) {
      this.form.splice(index, 1);
    },
    addMore() {
      this.navigatePush('MenuAll');
    },
    proceed() {
      if (this.isUndefinedNullOrEmpty(this.getCartItems)) return;
      // this.navigatePush('OrderCheckout');
      if (!this.isUndefinedNullOrEmpty(this.getPreSelectedChannel)) {
        this.selected(Number(this.getPreSelectedChannel));
      } else {
        if (this.$route.query?.storeId) {
          this.navigatePush('OrderCheckout', { channel: DELIVERY_CHANNEL_PATH.TAKEAWAY });
        } else {
          this.deliveryChannelModalShow = true;
        }
      }
    },
    selected(value) {
      switch (value) {
        case DELIVERY_CHANNEL.TAKEAWAY:
          this.selectedChannel = DELIVERY_CHANNEL.TAKEAWAY;
          this.overlay = true;
          // this.eventCB = () => {
          this.navigatePush('OrderCheckout', { channel: DELIVERY_CHANNEL_PATH.TAKEAWAY });
          // };
          // this.getFocItem();
          break;
        case DELIVERY_CHANNEL.PICK_UP:
          this.selectedChannel = DELIVERY_CHANNEL.PICK_UP;
          this.overlay = true;
          // this.eventCB = () => {
          this.navigatePush('OrderCheckout', { channel: DELIVERY_CHANNEL_PATH.PICK_UP }, { step: PICK_UP_STEP.PICK_UP_INFO });
          // };
          // this.getFocItem();
          break;
        case DELIVERY_CHANNEL.DELIVERY:
          this.selectedChannel = DELIVERY_CHANNEL.DELIVERY;
          this.overlay = true;
          // this.eventCB = () => {
          this.navigatePush('OrderCheckout', { channel: DELIVERY_CHANNEL_PATH.DELIVERY }, { step: DELIVERY_STEP.DELIVERY_INFO });
          // };
          // this.getFocItem();
          break;
        default:
          break;
      }
    },
    // getUpsellByType(type, amt, upsells) {
    //   let filteredUpsell = [];

    //   filteredUpsell = upsells.filter(item => item.type == type);
    //   if (!this.isUndefinedNullOrEmpty(filteredUpsell)) {
    //     for (let item of filteredUpsell) {
    //       if (amt < item.amt) {
    //         this.upsellMsgArr = item.msg;
    //         return true;
    //       }
    //     }
    //   }
    //   return false;
    // },
    filterUpsellArr(type, amt, upsells) {
      this.filteredUpsellArr = upsells.filter(item => item.type == this.UPSELL_TYPE.ALL || item.type == type);
      if (!this.isUndefinedNullOrEmpty(this.filteredUpsellArr)) {
        // for (let item of filteredUpsell) {
        //   if (amt < item.amt) {
        //     this.upsellMsgArr = item.msg;
        //     return true;
        //   }
        // }
        return true;
      }
      return false;
    },
    getFocItem() {
      return this.focList().then(res => {
        const { info, items, upsells } = res?.data;
        // if (this.isUndefinedNullOrEmpty(info)) {
        //   this.showError('Delivery fee info missing');
        // }
        // this.SET_DELIVERY_FEE(info);
        if (this.isUndefinedNullOrEmpty(items) && this.isUndefinedNullOrEmpty(upsells)) {
          this.addToFocCart([]);
          this.eventCB();
        }

        let upsellMsgFound = false;

        if (!this.isUndefinedNullOrEmpty(upsells)) {
          switch (this.selectedChannel) {
            case DELIVERY_CHANNEL.DELIVERY:
              upsellMsgFound = this.filterUpsellArr(this.UPSELL_TYPE.DELIVERY, this.totalPrice, upsells);
              break;
            case DELIVERY_CHANNEL.TAKEAWAY:
              upsellMsgFound = this.filterUpsellArr(null, this.totalPrice, upsells);
              break;
            case DELIVERY_CHANNEL.PICK_UP:
              upsellMsgFound = this.filterUpsellArr(this.UPSELL_TYPE.PICK_UP, this.totalPrice, upsells);
              break;
          }
          this.deliveryChannelModalShow = false;
        }

        if (!this.isUndefinedNullOrEmpty(items)) {
          this.focItemList = items.map(item => {
            const normalItemId = item.id - 500; // For FOC items, BE will add 500. So deduct 500 here to match the normal item in menuItemList.
            const menuItem = this.menuItemList.find(menuItem => menuItem.itemId == normalItemId);

            if (item.flag == this.FOC_ITEM_TYPE.SELECTABLE) {
              // auto select first item
              if (this.isUndefinedNullOrEmpty(this.selectableFocItem)) {
                this.selectableFocItem = item;
              }
            }
            if (item.flag == this.FOC_ITEM_TYPE.FIXED) this.fixedFocItem = item;

            return {
              ...item,
              images: menuItem.images,
            };
          });
          if (upsellMsgFound) {
            this.upsellCB = () => {
              this.upsellModalShow = false;
              this.deliveryChannelModalShow = false;
              this.focItemModalShow = true;
            };
            this.upsellModalShow = true;
          } else {
            this.deliveryChannelModalShow = false;
            this.focItemModalShow = true;
          }
        } else {
          if (upsellMsgFound) {
            this.upsellModalShow = true;
            this.upsellCB = () => {
              this.eventCB();
            };
          }
        }

        this.overlay = false;
      });
    },
    focList() {
      const params = {
        channel: this.selectedChannel,
        amt: Number(this.priceFormat(this.totalPrice)),
      };
      return menuItemApi.focList(params).then(res => {
        return res;
      });
    },
    skipFocItem() {
      this.addToFocCart([]);
      this.eventCB();
    },
    confirmFocItem() {
      this.focItemLoading = true;
      let focItemArr = [];

      if (!this.isUndefinedNullOrEmpty(this.selectableFocItem)) {
        focItemArr.push({
          menuItemId: this.selectableFocItem.id,
          label: this.selectableFocItem.name,
          qty: this.selectableFocItem.qty,
        });
      }

      if (!this.isUndefinedNullOrEmpty(this.fixedFocItem)) {
        focItemArr.push({
          menuItemId: this.fixedFocItem.id,
          label: this.fixedFocItem.name,
          qty: this.fixedFocItem.qty,
        });
      }

      if (this.isUndefinedNullOrEmpty(focItemArr)) {
        this.errMsgModalShow = true;
        this.focItemLoading = false;
        return;
      }

      this.addToFocCart(focItemArr);
      this.eventCB();
      this.focItemLoading = false;
    },
    getItemImg(imgUrl) {
      if (this.isUndefinedNullOrEmpty(imgUrl)) {
        return this.defaultImg;
      }
      return imgUrl;
    },
    selectFocItem(type, item) {
      switch (type) {
        case this.FOC_ITEM_TYPE.SELECTABLE:
          if (this.selectableFocItem?.id == item.id) {
            this.selectableFocItem = {};
          } else {
            this.selectableFocItem = item;
          }
          break;
        case this.FOC_ITEM_TYPE.FIXED:
          if (this.fixedFocItem?.id == item.id) {
            this.fixedFocItem = {};
          } else {
            this.fixedFocItem = item;
          }
          break;
        default:
          break;
      }
    },
    skipUpsell() {
      this.upsellCB();
    },
    upsellAddMore() {
      this.navigatePush('MenuAll');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../styles/mixin';
.page-wrap {
  // overflow-y: scroll;
  margin-bottom: 80px;
}
.cart-wrap {
  background-color: #fff;
  border-radius: 10px;

  .cart-img {
    max-width: 45px;
    width: 100%;
  }

  .set-item-wrap {
    .set-item {
      &__title {
        font-size: 1rem;
        font-weight: 500;
      }
    }
    .cart-item {
      font-size: 0.95rem;
    }
  }

  .set-item__price {
    color: #ff0000;
    font-size: 1rem;
    font-weight: 500;
    min-width: 120px;
  }

  .set-item-qty-wrap {
    min-width: 120px;
    max-width: 140px;
    @include for-sm {
      min-width: 100px;
      max-width: 110px;
    }
  }
}
.delete-icon {
  &::after {
    display: none;
  }
}
.bottom-bar {
  background-color: var(--primary-black-color);
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  padding: 12px;
  height: 80px;

  .total-text {
    color: var(--primary-color);
    font-size: 16px;
  }
  .total-price {
    color: var(--primary-color);
    font-size: 22px;
    font-weight: 500;
  }
}
.modal-body {
  background-color: #231f20;
  height: 100px;

  &__text {
    color: var(--primary-color);
    font-size: 24px;
    font-weight: 600;
  }
}
.menu-item-wrap {
  display: grid;
  grid-gap: 0.75rem;
  row-gap: 0.3rem;
  grid-template-columns: repeat(4, 1fr);
  @include for-sm {
    grid-template-columns: repeat(2, 1fr);
  }
}
.item-wrap {
  border: 2px solid #000;
  border-radius: 5px;
  min-height: 100px;
  &.selected {
    border: 3px solid #00c569;
    box-shadow: none;
    // box-shadow: 3px 3px 20px 0px #888888
  }
}
.item-img {
  max-width: 100px;
  width: 100%;
  @include for-sm {
    max-width: 90px;
  }
}
.foc-footer-wrap {
  .btn-no-border {
    border: none;
    border-radius: 0px;
  }
}
</style>
