<template>
  <CommonModal v-model="show" :modal-size="'modal-xs'" :header="false" :footer="false" :persistent="persistent">
    <template #body>
      <div class="channel-body px-5 py-6">
        <div
          v-for="(item, index) in channel"
          :key="index"
          class="box-wrap text-center cursor-pointer pa-5"
          :class="{ 'mb-5': index != channel.length - 1 }"
          @click="selected(item)"
        >
          {{ item.text }}
        </div>
      </div>
    </template>
  </CommonModal>
</template>
<script>
import CommonModal from '@/components/Modal';
export default {
  name: 'DeliveryChannelModal',
  components: {
    CommonModal,
  },
  props: {
    channelOpt: {
      type: Array,
      default: null,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      options: this.$t('opt.deliveryChannel'),
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    channel() {
      return this.channelOpt ? this.channelOpt : this.options;
    },
  },
  methods: {
    selected(item) {
      this.$emit('selected', item.value);
    },
  },
};
</script>
<style lang="scss" scoped>
.channel-body {
  // background-color: #231f20;
  // height: 100px;

  &__text {
    color: var(--primary-color);
    font-size: 24px;
    font-weight: 600;
  }
}
.box-wrap {
  border: 2px solid #6d6d6d;
  border-radius: 4px;
  background-color: #ffc840;
  font-size: 30px;
}
</style>
